<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <s-text
            v-model="MndObservation"
            @keyupEnter="approval(dateDisapproved, 3, true)"
            autofocus
            label="Observación"
          ></s-text
        ></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click="dialog = false">Cancelar</v-btn>
          <v-btn color="error" @click="approval(dateDisapproved, 3, true)"
            >Desaprobar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col>
      <v-sheet tile height="54" color="grey lighten-3" class="d-flex">
        <v-btn
          fab
          small
          color="primary"
          class="ma-2"
          @click="$refs.calendar.prev()"
        >
          <v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <v-spacer></v-spacer>
        <h1>{{ title }}</h1>
        <v-btn @click="report()" style="margin-left:10px;" color="success" icon
          ><v-icon>mdi-cloud-download</v-icon></v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          fab
          small
          color="primary"
          class="ma-2"
          @click="$refs.calendar.next()"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-sheet>
      <v-calendar
        @click:event="showEvent"
        ref="calendar"
        v-model="value"
        :events="events"
        locale="es"
        color="primary"
        :start="start"
        :end="end"
        @moved="moved"
      >
        <template #day="{ past,date}">
          <v-card
            style="padding-bottom:12px;"
            v-for="d in mealsDay(date)"
            elevation="0"
          >
            <div v-for="m in d.items">
              <v-card-subtitle
                class="text-center"
                style="padding-bottom:0px !important;padding-top:5px !important;font-size:12px !important;"
              >
                <b style="color:var(--v-primary-base)"
                  >{{ m.MealTypeText }} {{ m.MenuTypeText }}
                </b>
              </v-card-subtitle>
              <v-card-text style="padding:0px;" class="text-center">
                <div style="font-size:11px;">{{ m.ContentText }}</div>
              </v-card-text>
            </div>
            <v-card-actions v-if="d.items.length > 0 && !past">
              <v-row justify="center">
                <v-btn
                  :color="d.status == 3 ? 'error' : ''"
                  @click="approval(date, 3, false)"
                  small
                  fab
                >
                  <v-icon dark>mdi-close</v-icon>
                </v-btn>
                <v-btn
                  @click="approval(date, 2, false)"
                  :color="d.status == 2 ? 'success' : ''"
                  fab
                  small
                  style="margin-left:10px !important;"
                  ><v-icon>mdi-check</v-icon>
                </v-btn></v-row
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-calendar>
    </v-col>
  </div>
</template>

<script>
import MenuDailyService from "./../../services/DinningHall/MenuDailyService";
export default {
  name: "ValidatyDaily",
  components: {},
  data: () => ({
    dates: [],
    week: 0,
    value: null,
    title: "",
    dialog: false,
    MndObservation: "",
    dateDisapproved: null,
    events: [],
    start: null,
    end: null,
    meals: [],
  }),
  methods: {
    report() {
      MenuDailyService.report(this.start, this.end).then((r) => {
        this.$fun.downloadFile(
          r.data,
          this.$const.TypeFile.EXCEL,
          "Reporte de Programaciones"
        );
      });
    },
    moved({ date }) {
      this.calendar(date);
    },
    calendar(date) {
      this.dates = [];
      this.title = this.$moment(date)
        .format("MMMM")
        .toUpperCase();
      this.start = this.$moment(date).format("YYYY-MM-01");
      let days = this.$moment(this.start).daysInMonth();
      this.end = this.$moment(this.start)
        .add(days, "d")
        .format("YYYY-MM-DD");

      let obj = {
        search: "",
        dir: "",
        asc: "",
        length: 0,
        filter: {
          MltID: 0,
          MntID: 0,
          MndDateBegin: this.start,
          MndDateEnd: this.end,
        },
      };
      MenuDailyService.pagination(obj).then((r) => {
        this.meals = r.data.data;
        var daysInMonth = this.$moment().daysInMonth() + 6;
        let i = 0;
        while (i <= daysInMonth) {
          i++;
          var current = this.$moment(this.start)
            .date(i)
            .format("YYYY-MM-DD");
          let date = {
            start: current,
            items: this.meals.filter(
              (x) => this.$moment(x.MndDate).format("YYYY-MM-DD") == current
            ),
          };
          let status = 2;
          date.items.forEach((element) => {
            if (element.MndStatus == 3) {
              status = 3;
            }
            if (element.MndStatus == 1) {
              status = 1;
              return;
            }
          });
          date.status = status;
          this.dates.push(date);
        }
      });
    },
    approval(date, status, dialog) {
      if (!dialog && status == 3) {
        this.dialog = true;
        this.dateDisapproved = date;
      } else {
        this.dialog = false;
        let obj = {
          MndStatus: status,
          MndDate: date,
          MndObservation: this.MndObservation,
        };
        MenuDailyService.approval(obj).then((r) => {
          let d = this.dates.find((x) => {
            return x.start === date;
          });
          d.status = status;
          this.MndObservation = "";
        });
      }
    },
    mealsDay(date) {
      return this.dates.filter((x) => {
        return x.start === date;
      });
    },
    showEvent() {
      return this.events;
    },
    getEventColor(event) {
      return event.color;
    },
  },
  created() {
    this.calendar(this.$moment(new Date()));
  },
};
</script>

<style>
.scroll {
  overflow: scroll;
}
</style>
